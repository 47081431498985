import { ListView, DetailView, EditView } from './';

export default {
  path: 'businesspartner',
  childRoutes: [
    { path: 'list', name: 'List View', component: ListView },
    { path: 'view/:id', name: 'Detail View', component: DetailView },
    { path: 'edit/:id', name: 'Edit View', component: EditView },
  ],
};
