import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './AddFrontendDialogStyle';
import _ from 'lodash';
import WrapperDialog from './WrapperDialog';
import {
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(styles);

function AddFrontendDialog(props) {
  const classes = useStyles(props);
  const { dialogTitle, open, handleClose, handleSave } = props;
  const { organization, repository, language, description, capaiblities } = props.serviceConfig;

  const [selectedOrganizationValue, setSelectedOrganizationValue] = useState('');
  const handleChangeOrganization = e => {
    setSelectedOrganizationValue(e.target.value);
  };
  useEffect(() => {
    if (organization && organization.in && organization.in.length > 0) {
      setSelectedOrganizationValue(organization.in[0]);
    }
  }, [open]);

  const [selectedRepositoryValue, setSelectedRepositoryValue] = useState('');
  const handleChangeRepository = e => {
    setSelectedRepositoryValue(e.target.value);
  };
  useEffect(() => {
    if (repository && repository.value) {
      setSelectedRepositoryValue(repository.value);
    }
  }, [open]);

  const [selectedLanguageValue, setSelectedLanguageValue] = useState('');
  const handleChangeLanguage = e => {
    setSelectedLanguageValue(e.target.value);
  };
  useEffect(() => {
    if (language && language.in && language.in.length > 0) {
      setSelectedLanguageValue(language.in[0].name);
    }
  }, [open]);
  let selectedLanguageObj;
  if (language && language.in && language.in.length > 0) {
    let filteredLanguageList = language.in.filter(lang => {
      return lang.name === selectedLanguageValue;
    });
    if (filteredLanguageList.length > 0) {
      selectedLanguageObj = filteredLanguageList[0];
    }
  }

  const [expanded, setExpanded] = useState({});
  const handleChangeExpanded = title => () => {
    setExpanded(state => ({
      ...state,
      title: !state[title],
    }));
  };
  useEffect(() => {}, [open]);

  const [radioMap, setRadioMap] = useState({});
  const handleChangeRadioMap = capaiblity => field => e => {
    console.log('click:', e.target.value);
    console.log(JSON.stringify(radioMap, null, 2));
    setRadioMap(state => ({
      ...state,
      [capaiblity]: {
        ...state[capaiblity],
        [field]: {
          ...state[capaiblity][field],
          value: e.target.value,
        },
      },
    }));
  };
  useEffect(() => {
    for (let i = 0; i < capaiblities.length; i++) {
      let capa = capaiblities[i];
      for (let j = 0; j < capa.inputs.length; j++) {
        const input = capa.inputs[j];
        if (
          !radioMap[capa.title] ||
          !radioMap[capa.title][input.field] ||
          radioMap[capa.title][input.field].value == null
        ) {
          let currentCapaiblity = capaiblities.filter(
            capaiblity => capaiblity.title === capa.title,
          )[0];
          let currentInput = currentCapaiblity.inputs.filter(ipt => ipt.field === input.field)[0];
          if (currentCapaiblity) {
            if (!radioMap[capa.title]) {
              radioMap[capa.title] = {};
            }
            if (!radioMap[capa.title][input.field]) {
              radioMap[capa.title][input.field] = {};
            }
            setRadioMap(state => ({
              ...state,
              [capa.title]: {
                ...state[capa.title],
                [input.field]: {
                  ...state[capa.title][input.field],
                  value: currentInput.defaultValue,
                },
              },
            }));
          }
        }
      }
    }
  }, [open]);
  const getInputBy = capaiblities => capaiblity => field => {
    let currentCapaiblity = capaiblities.filter(capa => capa.title === capaiblity)[0];
    let currentField =
      currentCapaiblity.inputs &&
      currentCapaiblity.inputs.filter(input => input.field === field)[0];

    return currentField;
  };

  let content = (
    <React.Fragment>
      <Grid container justify="center" alignItems="center" spacing={2}>
        {/* line */}
        <Grid item container xs={12}>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">{`Organization`}</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedOrganizationValue}
                onChange={handleChangeOrganization}
                label={`Organization`}
              >
                {organization &&
                  organization.in &&
                  organization.in.map(org => {
                    return (
                      <MenuItem key={org} value={org}>
                        {org}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              fullWidth
              required
              id="outlined-name"
              label={`Repository`}
              value={selectedRepositoryValue}
              onChange={handleChangeRepository}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* line */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{`Framework`}</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              value={selectedLanguageValue}
              onChange={handleChangeLanguage}
            >
              {language &&
                language.in &&
                language.in.map(lang => {
                  return (
                    <FormControlLabel value={lang.name} control={<Radio />} label={lang.name} />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* line */}
        <Grid item xs={12}>
          <Typography color="textPrimary">
            {selectedLanguageObj && selectedLanguageObj.description}
          </Typography>
        </Grid>
        {/* line */}
        <Grid item xs={12}>
          {capaiblities && capaiblities.length > 0 && (
            <Typography color="primary">Capaiblities</Typography>
          )}
          {capaiblities &&
            capaiblities.map(capa => {
              return (
                <ExpansionPanel
                  className={classes.expansionPanel}
                  expanded={expanded[capa.title]}
                  onChange={handleChangeExpanded(capa.title)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>{capa.title}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container>
                      {capa.inputs &&
                        capa.inputs.map(input => {
                          return (
                            <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">{input.label}</FormLabel>
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name="gender1"
                                  value={
                                    radioMap[capa.title] && radioMap[capa.title][input.field].value
                                  }
                                  defaultValue={getInputBy(capaiblities)(capa.title)(input.field)}
                                  onChange={handleChangeRadioMap(capa.title)(input.field)}
                                >
                                  {input.in &&
                                    input.in.map(option => {
                                      return (
                                        <FormControlLabel
                                          value={option}
                                          control={<Radio />}
                                          label={option}
                                        />
                                      );
                                    })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );

  let handleConfigSave = () => {
    handleSave({
      organization: selectedOrganizationValue,
      repository: selectedRepositoryValue,
      language: selectedLanguageValue,
      capaiblities: radioMap,
    });
    setSelectedRepositoryValue('');
    setSelectedLanguageValue('');
    setSelectedOrganizationValue('');
    setRadioMap({});
    setExpanded({});
  };

  return (
    <WrapperDialog
      open={open}
      handleClose={() => handleClose()}
      handleSave={handleConfigSave}
      dialogTitle={dialogTitle}
      content={content}
      saveDisabled={!selectedRepositoryValue}
    />
  );
}

AddFrontendDialog.propTypes = {
  serviceConfig: PropTypes.shape({
    organization: PropTypes.shape({
      value: PropTypes.string.isRequired,
      in: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    repository: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
    language: PropTypes.shape({
      value: PropTypes.string.isRequired,
      in: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    capaiblities: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        inputs: PropTypes.arrayOf(
          PropTypes.shape({
            lable: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            defaultValue: PropTypes.string.isRequired,
            in: PropTypes.arrayOf(PropTypes.string).isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  dialogTitle: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default AddFrontendDialog;
