import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './ReuseServiceDialogStyle';
import _ from 'lodash';
import WrapperDialog from './WrapperDialog';
import { Typography, Grid, Button, Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles(styles);

function ReuseServiceDialog(props) {
  const classes = useStyles(props);
  const { services, dialogTitle, open, handleClose, handleSave } = props;

  let serviceList = (
    <React.Fragment>
      {services &&
        services.map(svc => {
          return (
            <Grid container justify="center" alignItems="center" spacing={8}>
              <Grid item xs={3}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className={classes.serviceName} color="primary">
                      {svc.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={7}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className={classes.serviceDescription}>
                      {svc.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => svc.onClickSetup({ reuseService: svc.name })}
                >
                  Select
                </Button>
              </Grid>
            </Grid>
          );
        })}
    </React.Fragment>
  );

  return (
    <WrapperDialog
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      dialogTitle={dialogTitle}
      content={serviceList}
      noSave={true}
    />
  );
}

ReuseServiceDialog.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      onClickSetup: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
  dialogTitle: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ReuseServiceDialog;
