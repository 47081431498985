//import axios from 'axios';
import {
  CLAIMS_BUSINESS_PARTNER_FETCH_LIST_BEGIN,
  CLAIMS_BUSINESS_PARTNER_FETCH_LIST_SUCCESS,
  CLAIMS_BUSINESS_PARTNER_FETCH_LIST_FAILURE,
  CLAIMS_BUSINESS_PARTNER_FETCH_LIST_DISMISS_ERROR,
  CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_BEGIN,
  CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_SUCCESS,
  CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_FAILURE,
  CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_DISMISS_ERROR,
} from './constants';

const mockRequest = url => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({})
    }, 1000);
  })
}

// Rekit uses redux-thunk for async actions by default: https://github.com/gaearon/redux-thunk
// If you prefer redux-saga, you can use rekit-plugin-redux-saga: https://github.com/supnate/rekit-plugin-redux-saga
export function fetchList(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: CLAIMS_BUSINESS_PARTNER_FETCH_LIST_BEGIN,
    });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      // const doRequest = args.error ? Promise.reject(new Error()) : Promise.resolve();
      const getBusinessPartnerURL = `/api/intelligent-claims/api/BusinessPartners/v1/`
      const doRequest = mockRequest(getBusinessPartnerURL)
      doRequest.then(
        res => {
          dispatch({
            type: CLAIMS_BUSINESS_PARTNER_FETCH_LIST_SUCCESS,
            data: {
              status: 'ok',
              totalResults: 0,
              articles: []
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: CLAIMS_BUSINESS_PARTNER_FETCH_LIST_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

export function fetchItem(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_BEGIN,
    });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      // const doRequest = args.error ? Promise.reject(new Error()) : Promise.resolve();
      const getBusinessPartnerItemURL = `/api/intelligent-claims/api/BusinessPartners/v1/${args.id}`
      const doRequest = mockRequest(getBusinessPartnerItemURL)
      doRequest.then(
        res => {
          dispatch({
            type: CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_SUCCESS,
            data: {
              status: 'ok',
              articles: {}
            }
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissFetchListError() {
  return {
    type: CLAIMS_BUSINESS_PARTNER_FETCH_LIST_DISMISS_ERROR,
  };
}

export function dismissFetchItemError() {
  return {
    type: CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLAIMS_BUSINESS_PARTNER_FETCH_LIST_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchListPending: true,
        fetchListError: null,
      };

    case CLAIMS_BUSINESS_PARTNER_FETCH_LIST_SUCCESS:
      // The request is success
      return {
        ...state,
        claimsBusinessPartnerList: action.data.articles,
        totalResultsCount: action.data.totalResults,
        fetchListPending: false,
        fetchListError: null,
      };

    case CLAIMS_BUSINESS_PARTNER_FETCH_LIST_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchListPending: false,
        fetchListError: action.data,
      };

    case CLAIMS_BUSINESS_PARTNER_FETCH_LIST_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchListError: null,
      };
    case CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchListPending: true,
        fetchListError: null,
      };

    case CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_SUCCESS:
      // The request is success
      return {
        ...state,
        claimsBusinessPartnerDetail: action.data.articles,
        fetchItemPending: false,
        fetchItemError: null,
      };
    case CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchItemPending: false,
        fetchItemError: action.data,
      };

    case CLAIMS_BUSINESS_PARTNER_FETCH_ITEM_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchItemError: null,
      };

    default:
      return state;
  }
}
