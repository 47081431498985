import { App } from '../features/home';
import eureka from 'eureka';
import homeRoute from '../features/home/route';
import _ from 'lodash';
import businessPartnerRoute from '../features/businesspartner/route';
import canvasRoute from '../features/canvas/route';

const { PageNotFound } = eureka.components;

const childRoutes = [homeRoute, businessPartnerRoute, canvasRoute];

const routes = [
  {
    path: '/',
    component: App,
    childRoutes: [
      ...childRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, child => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true;
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
