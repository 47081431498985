import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from './history'
import homeReducer from '../features/home/redux/reducer'
import businessPartnerReducer from '../features/businesspartner/redux/reducer'
import canvasReducer from '../features/canvas/redux/reducer'

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  businessPartner: businessPartnerReducer,
  canvas: canvasReducer
}

export default combineReducers(reducerMap)
