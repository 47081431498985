import React from 'react'
import ReactDOM from 'react-dom'
import Eureka from 'eureka'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/index.less'

Eureka.AppManager.registerApp(process.env.REACT_APP_NAME, {
  render (containerId, history, cb) {
    ReactDOM.render(<App history={history} />, document.getElementById(containerId), cb)
    serviceWorker.unregister()
  },
  unmount (containerId) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId))
  }
});
