import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  Alert,
  Icon,
  Table,
  //Status,
  Panel,
  Button,
  ButtonGroup,
  Pagination,
  FormSet,
  FormItem,
  FormLabel,
  FormInput,
  SearchInput,
  // FormSelect,
  MultiInput,
  ActionBar,
  DatePicker,
} from 'fundamental-react';
import { Link } from 'react-router-dom';
import eureka from 'eureka'
import { Typography, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';


const { Spinner } = eureka.components

export class ListView extends Component {
  static propTypes = {
    businessPartner: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  // constructor(props) {
  //   super(props);
  // }

  state = {
    searchTerm: '',
    pageCount: 1,
  };

  renderInitializing() {
    return (
      <div className="businesspartner-list-view list-view">
        <Spinner cssClass="page-load-spinner" />
      </div>
    );
  }

  componentDidMount() {
    this.props.actions.fetchList({}, '', '', 10, 1);
  }

  renderError(err) {
    return (
      <Alert style={{ margin: '10px' }} dismissible type="error">
        <Icon glyph="message-error" />
        Failed to load, please try again. &nbsp;&nbsp;
        <button className="fd-link" onClick={null}>Learn More</button>
      </Alert>
    );
  }

  render() {
    const {
      fetchListPending,
      claimsBusinessPartnerList,
      totalResultsCount,
      fetchListError,
    } = this.props.businessPartner;

    if (fetchListPending) {
      return this.renderInitializing();
    }

    if (fetchListError) {
      return this.renderError(fetchListError);
    }

    const tableHeader = [
      <input type="checkbox" />,
      'Document Number',
      'Business Partner',
      'Business Partner Name',
      'Business Partner Full Name',
      'First Name',
      'Last Name',
      'Middle Name',
      'Correspondence Language'
    ];
    // console.log(claimsBusinessPartnerList);
    const tableData = claimsBusinessPartnerList.map(item => {
      return {
        rowData: [
          <input type="checkbox" />,
          <Link to={"/businesspartner/view/" + item.Id}>{item.Id}</Link>,
          item.BusinessPartner,
          item.BusinessPartnerName,
          item.BusinessPartnerFullName,
          item.FirstName,
          item.LastName,
          item.MiddleName,
          item.CorrespondenceLanguage,
        ],
      };
    });

    return (
      <div className="businesspartner-list-view list-view">
        <Typography color="primary">material</Typography>
        <article className="fd-page">
          <header className="fd-page__header fd-has-background-color-background-2">
            <ActionBar>
              <ActionBar.Header description="" title="businessPartner" />
              <ActionBar.Actions>{}</ActionBar.Actions>
            </ActionBar>
            {/* <h3 className="page-title">businessPartner</h3> */}
            <div className="fd-container fd-container--fluid" style={{ marginBottom: '15px' }}>
              <div className="fd-col--2">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="input-1">Search</FormLabel>
                    <SearchInput
                      placeholder="Enter a search term"
                      inputProps={{
                        value: this.state.searchTerm,
                      }}
                      onEnter={text => {
                        console.log(text);
                        this.setState({ pageCount: 1, searchTerm: text }, () =>
                          this.props.actions.fetchList({}, text, '', 10, 1),
                        );
                      }}
                      // searchList={[
                      //   {
                      //     callback: function w() {},
                      //     text: 'apple',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'apricot',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'banana',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'blueberry',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'blackberry',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'calabash',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'clementines',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'kiwi',
                      //   },
                      //   {
                      //     callback: function w() {},
                      //     text: 'orange',
                      //   },
                      // ]}
                    />
                  </FormItem>
                </FormSet>
              </div>
              <div className="fd-col--2">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Customer</FormLabel>
                    <MultiInput
                      data={[
                        'Apple',
                        'Apricot',
                        'Acai',
                        'African Moringa',
                        'Bearberry',
                        'Bilberry',
                        'Blood orange',
                        'Barbadine',
                        'Barbados cherry',
                        'Balsam Apple',
                        'Chokeberry',
                        'Cranberry',
                        'Cupuacu',
                      ]}
                      onTagsUpdate={function w() {}}
                      placeHolder="Select a Fruit"
                    />
                  </FormItem>
                </FormSet>
              </div>
              <div className="fd-col--2">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Document Number</FormLabel>
                    <FormInput id="input-1" placeholder="Field placeholder text" type="text" />
                  </FormItem>
                </FormSet>
              </div>
              <div className="fd-col--2">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Document Date</FormLabel>
                    {/* <FormSelect id="select-1">
                      <option value="1">Duis malesuada odio volutpat elementum</option>
                      <option value="2">Suspendisse ante ligula</option>
                      <option value="3">Sed bibendum sapien at posuere interdum</option>
                    </FormSelect> */}
                    <DatePicker disableBeforeDate={new Date('2018-12-23T16:00:00.000Z')} />
                  </FormItem>
                </FormSet>
              </div>
              <div className="fd-col--2">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Posting Date</FormLabel>
                    <DatePicker disableBeforeDate={new Date('2018-12-23T16:00:00.000Z')} />
                    {/* <FormInput id="input-1" placeholder="Field placeholder text" type="text" /> */}
                  </FormItem>
                </FormSet>
              </div>
              <div className="fd-col--2" style={{ paddingTop: '35px' }}>
                <span className="fd-has-float-right">
                  <Button onClick={function w() {}} option="light">
                    Filters(3)
                  </Button>
                  <Button glyph="action" option="light" />
                </span>
              </div>
            </div>
          </header>
          <div className="fd-page__content fd-has-background-color-neutral-2 fd-has-padding-small">
            <Panel>
              <Panel.Header>
                <Panel.Head description="" title="Work Items (25)" />
                <Panel.Actions>
                  <Button glyph="sort" compact />
                  <Button glyph="download" compact />
                  <Button glyph="action-settings" compact />
                  <ButtonGroup>
                    <Button glyph="list" selected compact />
                    <Button glyph="line-charts" compact />
                  </ButtonGroup>
                </Panel.Actions>
              </Panel.Header>
              <Panel.Body>
                <Table headers={tableHeader} tableData={tableData} />
                <Pagination
                  style={{ margin: '0 0 10px 10px' }}
                  itemsPerPage={10}
                  initialPage={this.state.pageCount}
                  itemsTotal={totalResultsCount}
                  onClick={count => {
                    if (typeof count === 'number') {
                      this.setState({ pageCount: count }, () =>
                        this.props.actions.fetchList({}, this.state.searchTerm, '', 10, count),
                      );
                    }
                  }}
                />
              </Panel.Body>
            </Panel>
          </div>
        </article>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    businessPartner: state.businessPartner,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListView);
