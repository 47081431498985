export default theme => ({
  serviceName: {
    textAlign: 'center',
    fontSize: 18,
  },
  serviceDescription: {
    textAlign: 'center',
    fontSize: 12,
  },
  card: {
    backgroundColor: '#fafafa',
  },
});
