import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './WrapperDialogStyle';
import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const useStyles = makeStyles(styles);

function WrapperDialog(props) {
  const classes = useStyles(props);

  const { dialogTitle, open, handleClose, handleSave, content, noSave, saveDisabled } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {!noSave && (
          <Button disabled={saveDisabled} onClick={handleSave} color="primary">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

WrapperDialog.propTypes = {
  dialogTitle: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default WrapperDialog;
