import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Tile, Identifier } from 'fundamental-react';

export class DefaultPage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
  };

  state = {
    initializing: true,
  };

  componentWillMount() {
    this.props.history.push('/canvas');
  }

  renderError(err) {
    return (
      <Alert dismissible link="#" linkText=" link">
        Default alert with a
      </Alert>
    );
  }

  render() {
    return (
      <div className="home-page">
        <section className="fd-section">
          <div className="fd-section__header">
            <h3 className="fd-section__title">Featured</h3>
          </div>
          <div className="fd-panel-grid fd-panel-grid--4col">
            <div className="fd-panel">
              <div className="fd-panel__body">
                <Tile
                  role="button"
                  onClick={() => {
                    this.props.history.push('/canvas');
                  }}
                >
                  <Tile.Media>
                    <Identifier color={1} glyph="master-data" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Canvas" />
                </Tile>
                <Tile
                  role="button"
                  onClick={() => {
                    this.props.history.push('/businesspartner/list');
                  }}
                >
                  <Tile.Media>
                    <Identifier color={2} glyph="machine" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile role="button">
                  <Tile.Media>
                    <Identifier color={3} glyph="visits" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

export default connect(mapStateToProps)(DefaultPage);
