import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  Alert,
  Icon,
  //Status,
  Button,
  ActionBar,
  Popover,
  Menu,
  Tab,
  TabGroup,
  FormSet,
  FormItem,
  FormLabel,
  //TreeView,
} from 'fundamental-react';
import eureka from 'eureka'
const { Spinner } = eureka.components

export class DetailView extends Component {
  static propTypes = {
    businessPartner: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  renderInitializing() {
    return (
      <div className="businesspartner-list-view list-view">
        <Spinner cssClass="page-load-spinner" />
      </div>
    );
  }

  componentDidMount() {
    const id = this.props.location.pathname.slice(this.props.location.pathname.lastIndexOf('/') + 1)
    this.props.actions.fetchItem({id}, '', '', 10, 1);
  }

  renderError(err) {
    return (
      <Alert style={{ margin: '10px' }} dismissible type="error">
        <Icon glyph="message-error" />
        Failed to load, please try again. &nbsp;&nbsp;
        <button className="fd-link" onClick={null}>Learn More</button>
      </Alert>
    );
  }

  render() {
    const {
      fetchItemPending,
      claimsBusinessPartnerDetail,
      fetchItemError,
    } = this.props.businessPartner;

    if (fetchItemPending) {
      return this.renderInitializing();
    }

    if (fetchItemError) {
      return this.renderError(fetchItemError);
    }
    console.log(claimsBusinessPartnerDetail)
    return (
      <div className="businesspartner-detail-view detail-view">
        <article className="fd-page">
          <header className="fd-page__header fd-has-background-color-background-2">
            <ActionBar>
              <ActionBar.Back
                onClick={e => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
              />
              <ActionBar.Header description="" title={`Business Partner: ${claimsBusinessPartnerDetail.BusinessPartnerFullName} (${claimsBusinessPartnerDetail.BusinessPartner})`} />
              <ActionBar.Actions>
                <Popover
                  body={
                    <Menu>
                      <Menu.List>
                        <Menu.Item url="/">Option 1</Menu.Item>
                        <Menu.Item url="/">Option 2</Menu.Item>
                        <Menu.Item url="/">Option 3</Menu.Item>
                        <Menu.Item url="/">Option 4</Menu.Item>
                      </Menu.List>
                    </Menu>
                  }
                  control={<Button glyph="vertical-grip" option="light" />}
                  placement="bottom-end"
                />
                <Button>Copy</Button>
                <Button
                  option="emphasized"
                  onClick={() => {
                    this.props.history.push(`/businesspartner/edit/${claimsBusinessPartnerDetail.Id}`);
                  }}
                >
                  Edit
                </Button>
              </ActionBar.Actions>
            </ActionBar>
            <div className="fd-container fd-container--fluid" style={{ marginBottom: '15px' }}>
              <div className="fd-col--3">
                <FormSet>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Business Partner:</FormLabel>
                    <span className="form-text">{claimsBusinessPartnerDetail.BusinessPartner}</span>
                  </FormItem>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Business Partner Name:</FormLabel>
                    <span className="form-text">{claimsBusinessPartnerDetail.BusinessPartnerName}</span>
                  </FormItem>
                  <FormItem>
                    <FormLabel htmlFor="select-1">Business Partner Full Name:</FormLabel>
                    <span  className="form-text">{claimsBusinessPartnerDetail.BusinessPartnerFullName}</span>
                  </FormItem>
                </FormSet>
              </div>
            </div>
          </header>
          <div className="fd-page__content fd-has-background-color-neutral-2">
            <TabGroup>
              <Tab id="1" title="Middle Part">
                <section className="fd-section">
                  <div
                    className="fd-container fd-container--fluid"
                    style={{ marginBottom: '15px' }}>
                    <div className="fd-col--4">
                      <FormSet>
                        <FormItem>
                          <FormLabel htmlFor="select-1">First Name:</FormLabel>
                          <span className="form-text">{claimsBusinessPartnerDetail.FirstName}</span>
                        </FormItem>
                        <FormItem>
                          <FormLabel htmlFor="select-1">Last Name:</FormLabel>
                          <span className="form-text">{claimsBusinessPartnerDetail.LastName}</span>
                        </FormItem>
                      </FormSet>
                    </div>
                    <div className="fd-col--4">
                      <FormSet>
                        <FormItem>
                          <FormLabel htmlFor="select-1">Middle Name:</FormLabel>
                          <span className="form-text">{claimsBusinessPartnerDetail.MiddleName}</span>
                        </FormItem>
                        <FormItem>
                          <FormLabel htmlFor="select-1">Correspondence Language:</FormLabel>
                          <span className="form-text">{claimsBusinessPartnerDetail.CorrespondenceLanguage}</span>
                        </FormItem>
                      </FormSet>
                    </div>
                  </div>
                </section>
              </Tab>
              <Tab id="2" title="Search Term">
                <section className="fd-section">
                  <div
                    className="fd-container fd-container--fluid"
                    style={{ marginBottom: '15px' }}>
                    <div className="fd-col--4">
                      <FormSet>
                        <FormItem>
                          <FormLabel htmlFor="select-1">Search Term:</FormLabel>
                          <span className="form-text">{claimsBusinessPartnerDetail.SearchTerm1}</span>
                        </FormItem>
                      </FormSet>
                    </div>
                  </div>
                </section>
              </Tab>
            </TabGroup>
          </div>
        </article>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    businessPartner: state.businessPartner,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailView);
